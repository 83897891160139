
const config = {
    serverUri:  "https://cms.shiftmexico.com",
    //serverUri: process.env.server_uri ? process.env.server_uri : "https://shift-cms-dev.ironbit.net",
    //serverUri: "https://shift-cms-dev.ironbit.net",
    environment: process.env.environment ? process.env.environment : "prod",
    stripePublicKey: process.env.STRIPE_PK,
    firebaseConfig: {
        /*apiKey: process.env.firebase_api_key || "AIzaSyC6-FlRfUmcjENYlEAk2bKTjOaEBkhL8Aw",
        authDomain: process.env.firebase_api_key ||  "swift-92ae6.firebaseapp.com",
        projectId: process.env.firebase_project_id || "swift-92ae6",
        storageBucket: process.env.firebase_storage_bucket || "swift-92ae6.appspot.com",
        messagingSenderId: process.env.firebase_message_sender_id || "29849025936",
        appId: process.env.firebase_app_d || "1:29849025936:web:9ef4cca73e489757980715",
        measurementId: process.env.firebase_measurement_id  ||  "G-CZZH61JPMK"*/        
        /*
        apiKey:  "AIzaSyC6-FlRfUmcjENYlEAk2bKTjOaEBkhL8Aw",
        authDomain: "swift-92ae6.firebaseapp.com",
        projectId: "swift-92ae6",
        storageBucket:  "swift-92ae6.appspot.com",
        messagingSenderId: "29849025936",
        appId: "1:29849025936:web:9ef4cca73e489757980715",
        measurementId:   "G-CZZH61JPMK"*/
        // // DESARROLLO
        // apiKey: "AIzaSyDHVNMn1Cb4DbVP18Ws3nABVTqVZYyj_ls",
        // authDomain: "shift-dev-69ba9.firebaseapp.com",
        // projectId: "shift-dev-69ba9",
        // storageBucket: "shift-dev-69ba9.appspot.com",
        // messagingSenderId: "1065838108902",
        // appId: "1:1065838108902:web:28ff1b289f18c059db1b27",
        // measurementId: "G-Z9K2KHLHD9"
        // PRODUCCION
        apiKey: "AIzaSyBZsQvRMfEwHUFLfqS2gUtb8URjmpKzHsQ",
        authDomain: "shift-82764.firebaseapp.com",
        projectId: "shift-82764",
        storageBucket: "shift-82764.appspot.com",
        messagingSenderId: "610417333728",
        appId: "1:610417333728:web:83f497564727f24fc88785",
        measurementId: "G-N74S1VC5RL"

    },
    sections: {
        home: {
            name: "Inicio"
        },
        entrenamiento: {
            name: "Entrenamiento",
            id: "65301f5d965e9b12688fd045"
        },
        menteYCuerpo: {
            name: "Mente y cuerpo",
            id: "65301f7b965e9b12688fd049"
        }
    },
    version: '0.17.2'
}


export function firebaseInit(firebase) {

    if (!firebase.apps.length) {
        firebase.initializeApp(config.firebaseConfig);
    } else {
        firebase.app(); // if already initialized, use that one
    }
}



export default config; 
